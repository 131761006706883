import { Suspense, lazy, useCallback, useMemo } from 'react';
import gql from 'graphql-tag';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import {
  BlockRadioGroupOption,
  Loader,
  SwitchButton,
  Tooltip,
} from '@noloco/components';
import BuildModeInput from '@noloco/core/src/components/buildMode/BuildModeInput';
import BuildModeLabel from '@noloco/core/src/components/buildMode/BuildModeLabel';
import BuildModeSection from '@noloco/core/src/components/buildMode/BuildModeSection';
import { ALL } from '@noloco/core/src/constants/authVisibilityRules';
import { CUSTOM_RULES } from '@noloco/core/src/constants/buildMode';
import { DEFAULT_ROLES } from '@noloco/core/src/constants/defaultRoleReferences';
import { ALL as ALL_DEVICES } from '@noloco/core/src/constants/deviceRuleVisibilityRules';
import { deviceVisibilityRules } from '@noloco/core/src/constants/deviceRuleVisibilityRules';
import {
  CUSTOM_VISIBILITY_RULES,
  USER_ROLES,
} from '@noloco/core/src/constants/features';
import internalUsersVisibilityRules from '@noloco/core/src/constants/internalUsersVisibilityRules';
import roleVisibilityRules from '@noloco/core/src/constants/roleVisibilityRules';
import { getIconFromValue } from '@noloco/core/src/elements/Icon';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { ElementPath, VisibilityRules } from '@noloco/core/src/models/Element';
import { IconValue } from '@noloco/core/src/models/IconValue';
import { Project } from '@noloco/core/src/models/Project';
import { getCollectionDataQueryString } from '@noloco/core/src/queries/project';
import { getValidPlanLangKey } from '@noloco/core/src/utils/features';
import useCacheQuery from '@noloco/core/src/utils/hooks/useCacheQuery';
import useSpaces from '@noloco/core/src/utils/hooks/useSpaces';
import { getText } from '@noloco/core/src/utils/lang';
import {
  UpdatePropertyCallback,
  useUpdateVisibilityRules,
} from '../../utils/hooks/projectHooks';
import { useFeatureLimit } from '../../utils/hooks/useFeatureLimit';
import useIsFeatureEnabled from '../../utils/hooks/useIsFeatureEnabled';
import useIsTrialing from '../../utils/hooks/useIsTrialing';
import useValidFeaturePlan from '../../utils/hooks/useValidFeaturePlan';
import Guide from '../Guide';
import ProFeatureBadge from '../ProFeatureBadge';

const LazyCustomVisibilityRulesEditor = lazy(
  () => import('./CustomVisibilityRulesEditor'),
);

const LANG_KEY = 'rightSidebar.visibility';

const BaseVisibilityOption = ({
  description,
  label,
  active,
  checked,
  Icon,
  onClick,
  showBadge = false,
  locked = false,
  disabled = locked && !checked,
}: any) => {
  const badgePlan = useValidFeaturePlan(USER_ROLES);

  return (
    <Tooltip
      disabled={!locked}
      content={
        <>
          <span className="flex">{getText(LANG_KEY, 'roles.disabled')}</span>
          {checked && (
            <span>{getText(LANG_KEY, 'roles.disabledWithChecked')}</span>
          )}
        </>
      }
      bg="white"
    >
      {/* @ts-expect-error TS(2322): Type '{ children: any; active: any; badgeText: str... Remove this comment to see the full error message */}
      <BlockRadioGroupOption
        active={active}
        badgeText={getText(
          'billing.plans',
          getValidPlanLangKey(badgePlan),
          'name',
        )}
        checked={checked}
        description={description}
        disabled={disabled}
        Icon={Icon}
        onClick={onClick}
        showBadge={showBadge}
      >
        {label}
      </BlockRadioGroupOption>
    </Tooltip>
  );
};

interface VisibilityRulesEditorProps {
  dataType?: DataType;
  elementPath?: ElementPath;
  hideGuide?: boolean;
  selectSpaces?: boolean;
  onChange?: UpdatePropertyCallback;
  project: Project;
  visibilityRules?: VisibilityRules;
}

const VisibilityRulesEditor = ({
  dataType,
  elementPath = [],
  hideGuide = false,
  selectSpaces = false,
  onChange,
  project,
  visibilityRules,
}: VisibilityRulesEditorProps) => {
  const {
    customRules = [],
    deviceRule,
    roleRule,
    roles = [],
    spaces: visibilityRuleSpaces = [],
    type,
  } = visibilityRules || {};
  const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);
  const isTrialing = useIsTrialing();
  const roleLimit = useFeatureLimit(USER_ROLES);
  const spaces = useSpaces();

  const [updateVisibilityRules] = useUpdateVisibilityRules(
    elementPath,
    project,
  );

  const onUpdateVisibilityRules = useCallback(
    (path, value) => {
      if (onChange) {
        return onChange(path, value);
      }

      updateVisibilityRules(path, value);
    },
    [onChange, updateVisibilityRules],
  );

  const roleExceedsLimit = useCallback(
    (index: number) => {
      if (isNil(roleLimit.limit)) {
        return false;
      }

      return index >= roleLimit.limit;
    },
    [roleLimit.limit],
  );

  const rolesQueryString = getCollectionDataQueryString('role', {
    edges: {
      node: {
        id: true,
        name: true,
        referenceId: true,
      },
    },
  });

  const { data: rolesData } = useCacheQuery(
    gql`
      ${rolesQueryString}
    `,
    {
      context: {
        projectQuery: true,
        projectName: project.name,
      },
    },
  );

  const userRoles = useMemo(
    () =>
      get(rolesData, 'roleCollection.edges', []).map((edge: any) => ({
        ...edge.node,
        isDefault: DEFAULT_ROLES.includes(edge.node.referenceId),
      })),
    [rolesData],
  );

  const toggleRole = useCallback(
    (roleRefId) => {
      if (roles.includes(roleRefId)) {
        onUpdateVisibilityRules(
          ['roles'],
          roles.filter((id) => id !== roleRefId),
        );
      } else {
        onUpdateVisibilityRules(['roles'], [...roles, roleRefId]);
      }
    },
    [roles, onUpdateVisibilityRules],
  );

  const toggleSpace = useCallback(
    (spaceId) =>
      onUpdateVisibilityRules(
        ['spaces'],
        visibilityRuleSpaces.includes(spaceId)
          ? visibilityRuleSpaces.filter((id) => id !== spaceId)
          : [...visibilityRuleSpaces, spaceId],
      ),
    [visibilityRuleSpaces, onUpdateVisibilityRules],
  );

  const getSpaceIcon = useCallback(
    (icon: IconValue) => getIconFromValue(icon),
    [],
  );

  const showSpaceSelector = useMemo(() => {
    if (!selectSpaces) {
      return false;
    }

    const elementIsHidden = get(
      project,
      ['elements', ...elementPath, 'props', 'hide'],
      false,
    );

    if (elementIsHidden) {
      return false;
    }

    return spaces.length > 0;
  }, [selectSpaces, project, elementPath, spaces.length]);

  return (
    <>
      <div className="space-y-2 p-2">
        {!hideGuide && (
          <div className="mb-4 text-sm">
            <Guide
              href="https://guides.noloco.io/record-pages/visibility-settings"
              showTooltip={true}
              video="https://www.youtube.com/embed/0w5m3PpYEgM?si=6X0-G5-1l3L-HpA-"
            >
              {getText(LANG_KEY, 'description')}
            </Guide>
          </div>
        )}
        <BuildModeInput inline={true} label={getText(LANG_KEY, 'type.label')}>
          <SwitchButton
            className="h-8 w-full rounded-lg"
            onChange={(newValue) => onUpdateVisibilityRules(['type'], newValue)}
            options={internalUsersVisibilityRules.map((authRule) => ({
              value: authRule,
              label: getText(LANG_KEY, 'type.title', authRule),
            }))}
            value={type || ALL}
          />
        </BuildModeInput>
        <p className="text-xs text-slate-400">
          {getText(LANG_KEY, 'type.visibleTo')}
          <span className="lowercase">
            {getText(LANG_KEY, 'type.description', type || ALL)}
          </span>
        </p>
      </div>
      <hr className="my-2 border-slate-700" />
      <div className="p-2">
        <BuildModeInput inline={true} label={getText(LANG_KEY, 'roles.label')}>
          <SwitchButton
            className="h-8 w-full rounded-lg"
            onChange={(newValue) =>
              onUpdateVisibilityRules(['roleRule'], newValue)
            }
            options={roleVisibilityRules.map((roleVisibilityRule) => ({
              value: roleVisibilityRule,
              label: getText(
                LANG_KEY,
                'roles.compactTitle',
                roleVisibilityRule,
              ),
            }))}
            value={roleRule || ALL}
          />
        </BuildModeInput>
        <p className="my-2 text-xs text-slate-400">
          {getText(LANG_KEY, 'type.visibleTo')}
          <span className="lowercase">
            {getText(LANG_KEY, 'roles', roleRule || ALL)}
          </span>
        </p>
        {roleRule && roleRule !== ALL && (
          <div className="space-y-4">
            <div className="flex flex-col">
              {userRoles.map((role: any, index: number) => (
                <BaseVisibilityOption
                  active={false}
                  checked={roles.includes(role.referenceId)}
                  key={role.referenceId}
                  label={role.name}
                  locked={
                    !role.isDefault && roleExceedsLimit(index) && !isTrialing
                  }
                  onClick={() => toggleRole(role.referenceId)}
                  showBadge={
                    !role.isDefault && (roleExceedsLimit(index) || isTrialing)
                  }
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <hr className="my-2 border-slate-700" />
      <div className="space-y-2 p-2">
        <BuildModeInput inline={true} label={getText(LANG_KEY, 'device.label')}>
          <SwitchButton
            className="h-8 w-full rounded-lg"
            onChange={(newValue) =>
              onUpdateVisibilityRules(['deviceRule'], newValue)
            }
            options={deviceVisibilityRules.map((deviceRule) => ({
              label: getText(LANG_KEY, 'device.title', deviceRule),
              value: deviceRule === ALL_DEVICES ? undefined : deviceRule,
            }))}
            value={deviceRule}
          />
        </BuildModeInput>
        <p className="text-xs text-slate-400">
          {getText(LANG_KEY, 'type.visibleTo')}
          <span className="lowercase">
            {getText(LANG_KEY, 'device.description', deviceRule || ALL_DEVICES)}
          </span>
        </p>
      </div>
      {showSpaceSelector && (
        <>
          <hr className="my-2 border-slate-700" />
          <div className="p-2">
            <BuildModeLabel>{getText(LANG_KEY, 'spaces.label')}</BuildModeLabel>
            <p className="my-2 text-xs text-slate-400">
              {getText(LANG_KEY, 'spaces.visibleTo')}
            </p>
            <div className="flex flex-col">
              {spaces.map(({ id, space: { icon, name } }) => (
                <BaseVisibilityOption
                  active={false}
                  checked={visibilityRuleSpaces.includes(id)}
                  Icon={getSpaceIcon(icon)}
                  key={id}
                  label={name}
                  onClick={() => toggleSpace(id)}
                />
              ))}
            </div>
          </div>
        </>
      )}
      <BuildModeSection
        id={CUSTOM_RULES}
        className="border-b border-t"
        title={getText(LANG_KEY, 'custom.label')}
        endComponent={
          <div>
            <ProFeatureBadge
              feature={CUSTOM_VISIBILITY_RULES}
              inline={true}
              showAfterTrial={!customRulesEnabled}
            />
          </div>
        }
      >
        {customRulesEnabled && (
          <div className="p-2">
            <Suspense fallback={<Loader />}>
              <LazyCustomVisibilityRulesEditor
                customRules={customRules}
                dataType={dataType}
                elementPath={elementPath}
                onUpdateVisibilityRules={onUpdateVisibilityRules}
                project={project}
              />
            </Suspense>
          </div>
        )}
      </BuildModeSection>
    </>
  );
};

export default VisibilityRulesEditor;
